<template>
  <div>
    <UIExit label="Exit Exam Planner Documentation" :exit-url="defaultRedirect" />
    <TitleWrapper
      :filter-option="false"
      title="EXAM_PLANNER"
      tooltip-title="EXAM_PLANNER"
      :display-breadcrumb="true"
    />
    <div class="bg-white">
      <!--  Section -->
      <documentation :document-list="questionList" answer-page="exam-question" />
    </div>
  </div>
</template>

<script>
import documentation from '@src/router/layouts/app-document/documentation.vue'
import TitleWrapper from '@/src/components/TitleWrapper.vue'
import UIExit from '@/src/components/UiElements/UIExit.vue'
import ROUTE from '@/src/constants/route-constants.js'
import EXAM_PLANNER_DATA from '@/src/router/layouts/app-document/exam-planner-documentation/exam-planner.json'

export default {
  components: {
    documentation,
    TitleWrapper,
    UIExit,
  },
  page: {
    title: 'syllabus Documentation',
    meta: [{ name: 'syllabus manager documentation', content: 'syllabus manager documentation' }],
  },
  data() {
    return {
      landingPage: 'landingPage',
      defaultRedirect: ROUTE.QUICK_ACTIONS.name,
      questionList: EXAM_PLANNER_DATA.questionList,
    }
  },
}
</script>
