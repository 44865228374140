<template>
  <div
    class="mx-auto container py-8 md:py-15 sm:py-15.5 px-8 md:px-15 lg:px-20 xl:px-24 mt-5 sm:mt-8 h-56vh overflow-y-auto"
  >
    <ul class="list-decimal list-inside">
      <div v-for="(value, idx) in documentList" :key="idx">
        <div
          v-if="value.title"
          class="font-bold text-rich-black text-1xl sm:text-2xl md:text-4xl text-center pb-5 lg:pb-10"
        >
          {{ value.title }}
        </div>
        <li v-if="value.question" class="font-semibold text-lg text-gray-600 lg:ml-15 mb-5">
          <span
            class="cursor-pointer hover:text-primary-green hover:underline"
            @click="redirectToAnswer(value.id)"
          >
            {{ value.question }}
          </span>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Documentation',

  props: {
    documentList: { type: [Array, Object], default: () => [] },
    answerPage: { type: String, default: null },
  },
  data() {
    return {
      landingPage: 'landingPage',
    }
  },
  methods: {
    redirectToAnswer(id) {
      this.$router?.push({ name: this.answerPage, params: { id: id } })
    },
  },
}
</script>
